<ng-container *ngFor="let grupo of grupos">

    <div class="row">
        <div class="divhr">
            <p class="fs-4 text-center pt05rem corTituloSobBarra">
                {{ grupo.label }}
            </p>
            <hr class="classhr">
        </div>
        <div class="row">
            <ng-container *ngFor="let campo of grupo.campos">
                <div class="col-sm-6">

                    <ng-container [ngSwitch]="campo.tipoComponente">

                        <div *ngSwitchCase="'a_href'">
                            <blockquote class="blockquote">
                                <a href="{{ campo.valor }}" target="_blank">
                                    <span class="form-label">{{ campo.label }}</span>
                                </a>
                            </blockquote>
                        </div>

                        <div *ngSwitchCase="'checkbox'">

                            <mat-label>
                                <p class="text-justify">
                                    <i *ngIf="grupo.tipoLayout == 2" class="bi bi-check"></i>
                                    {{ campo.label}}
                                </p>
                            </mat-label>

                        </div>

                        <div *ngSwitchDefault>

                            <mat-label style="font-weight: bold;">{{ campo.label}} : </mat-label>
                            <mat-label *ngIf="campo.valor">
                                <span *ngFor="let valor of campo.valor; last as isLast">
                                    {{ valor.text || valor.valor }} <span *ngIf="!isLast">,</span>
                                </span></mat-label>
                            <mat-label *ngIf="campo.valor.length == 0">NÃO INFORMADO</mat-label>

                        </div>

                    </ng-container>

                </div>
            </ng-container>
        </div>
    </div>

</ng-container>

<div class="mt-4">
    <button mat-raised-button color="secondary" matStepperPrevious>Voltar</button>
    <button style="margin-left: 5px;" mat-button color="default"
        (click)="confirmarCriacaoRequerimento();$event.preventDefault();">Enviar</button>
</div>

<mat-error class="mt-3" *ngIf="messageError">{{ messageError }}</mat-error>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#3f51b5" type="ball-atom" name="conclusaoSpinner">
    <p style="font-size: 20px; color: white">Salvando Requerimento ...</p>
</ngx-spinner>
import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";
import * as moment from "moment";
import { Campo } from "src/app/core/models/domain/Campo";

/*
    Responsável por prover as funções globais para validação
*/

// Valida o tipo de arquivo/extensões
export function requiredFileType(types: string[], control: FormControl, campo: Campo) {

    const file = control.value;
    if (file) {

        let values = file.toString().toLowerCase().split('.');

        const extension = values[values.length - 1];
        if (types.includes(extension)) {
            return false;
        }

        return true;

    }

    return false;

}

// Valida o tamanho do arquivo
export function fileSizeValidator(fileParam: File, size: number, control: FormControl) {

    const file = control.value;
    if (file && fileParam) {
        const fileSize = fileParam.size;
        const fileSizeInKB = Math.round(fileSize / 1024);
        if (fileSizeInKB <= size) {
            return false;
        }

        return true;

    }
    return false;

}

// Date Validator
export function dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

        if (control?.value) {
            const date = moment(control?.value);
            if (date?.isValid()) {
                return null;
            }
        }

        return { 'invalidDate': true }
    }
}
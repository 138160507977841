import { CampoEnum } from "src/app/core/components/requerimento/abertura/stepper/step/grupo/campo/util/campo-enum";
import { isLink } from "src/app/core/components/requerimento/abertura/stepper/step/grupo/campo/util/campo-util";
import { Step } from "src/app/core/components/requerimento/abertura/stepper/step/step";
import { TipoComponenteAppEnum } from "src/app/core/components/requerimento/abertura/utils/tipo-componente-app-enum";
import { Campo } from "src/app/core/models/domain/Campo";
import { Grupo } from "src/app/core/models/domain/Grupo";
import { FormControlMetadados } from "../models/form-control-metadados";

export function criarMetaDadosCampo(campo: Campo) {

    const nome = campo.nome;
    const label = campo.label;
    const multiValorado = campo.multiValorado;
    const obrigatorio = campo.obrigatorio;
    const valor = isLink(campo) ? campo.opcoes[0].valor : null;
    const tipo = CampoEnum[campo.tipoComponente as CampoEnum]

    return new FormControlMetadados(nome, label, tipo, multiValorado ? TipoComponenteAppEnum.CAMPOS_MULTIVALORADOS : TipoComponenteAppEnum.CAMPO,
        multiValorado, valor, obrigatorio, null, null);
}

export function criarMetaDadosGrupo(grupo: Grupo) {

    const label = grupo.label;
    const multiValorado = grupo.multiValorado;
    const ordem = grupo.ordem;
    const tipoLayout = grupo.tipoLayout;

    return new FormControlMetadados(null, label, null, multiValorado ? TipoComponenteAppEnum.GRUPOS_MULTIVALORADOS : TipoComponenteAppEnum.GRUPO,
        multiValorado, null, true, ordem, tipoLayout);
}

export function criarMetaDadosPasso(step: Step) {

    const label = step.label;

    return new FormControlMetadados(null, label, null, TipoComponenteAppEnum.PASSO,
        false, null, true, null, null);
}
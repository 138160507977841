import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FilesRequerimento } from '../models/domain/FilesRequerimento';
import { Observable } from 'rxjs';
import { Requerimento } from '../models/domain/requerimento-class.model';
import { RequerimentoFile } from '../models/domain/requerimento-file';


@Injectable({ providedIn: 'root' })
export class FileService {

    private requerimentoUrl: string;


    constructor(private http: HttpClient) {
        this.requerimentoUrl = environment.apiUrlFile + '/files/';
    }

    get() {
        return this.http.get<FilesRequerimento[]>(`${environment.apiUrlFile}/file/files`);
    }

    public gerarPDF(form: any): Observable<Requerimento> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post<Requerimento>(environment.apiUrlFile + '/pdf', JSON.stringify(form), { headers: headers });
    }

    public gerarRequerimentoPDF(form: any, filesToUpload: RequerimentoFile[]): Observable<Requerimento> {

      // Create form data
      const formData = new FormData(); 
        
      // Store form name as "file" with file data
      filesToUpload.forEach( (requerimentoFile) => {
        formData.append("files", requerimentoFile.value, requerimentoFile.nome);
      });

      // Append the json object to formData
      formData.append("stepperDataDTO", new Blob([JSON.stringify(form)], {type:"application/json"}));

      // Send formData using post method to API File
      return this.http.post<any>(environment.apiUrlFile + '/file/gerarRequerimentoPDF', formData);

    }

}
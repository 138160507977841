<div>
    <mat-card>
        <mat-card-header>
            <p>Autenticando...</p>
        </mat-card-header>
        <mat-card-content style="justify-content: center;display: flex;">
            <mat-spinner diameter="50" strokeWidth="4"></mat-spinner>
        </mat-card-content>
    </mat-card>
</div>

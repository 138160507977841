import { Requerimento } from "./requerimento-class.model";


export class AcessoExterno {

    dataExpiracao: Date;
    emailRequerente: string;
    idRequerimento: Requerimento;
    token: string;

    constructor(
        dataExpiracao: Date,
        emailRequerente: string,
        idRequerimento: Requerimento,
        token: string
    ) {
        this.dataExpiracao = dataExpiracao;
        this.emailRequerente = emailRequerente;
        this.idRequerimento = idRequerimento;
        this.token = token;
    }


}

import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  handle(error: any): void {
    console.error('Ocorreu um erro em:', error);
    throwError(() => 'Erro');
  }
  
}

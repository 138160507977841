<form #{{refName}}="ngForm" [formGroup]="formGroupName" class="row g-3 needs-validation form-inline">

<ng-container  *ngFor="let grupo of filtraGrupoPasso(3)">
    <div *ngIf="grupo" class="col-12 text-center mb-4"><span class="display-6 fw-bold">{{ grupo.label }}</span></div>
    <ng-container  *ngFor="let campo of grupo?.camposGrupos">
      <div class="col-md-12" *ngIf="campo.tipoComponente === 'input'">
        <label for="{{ campo.nome }}" class="form-label">{{ campo.label }}</label>
        <input type="text" class="form-control"  [disabled]="false" [readonly]="false" id="{{ campo.nome }}" name="{{ campo.nome }}"  formControlName="{{ campo.nome }}" placeholder="{{ campo.label }}">
      </div>
      <div class="col-md-12" *ngIf="campo.tipoComponente === 'a_href'">
        <a href="{{ campo.opcoesCampo[0].valor }}" target="_blank">
          <label for="{{ campo.nome }}" class="form-label">{{ campo.label }}</label>
        </a>
      </div>
      <div class="col-md-12" *ngIf="campo.tipoComponente === 'calendar'">
        <label for="{{ campo.nome }}" class="form-label">calendar</label>
      </div>
      <div class="col-md-12" *ngIf="campo.tipoComponente === 'checkbox'">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="{{ campo.nome }}" id="{{ campo.nome }}"  formControlName="{{ campo.nome }}" [disabled]="false" [readonly]="false">
          <label class="form-check-label" for="{{ campo.nome }}">
            {{ campo.label }}
          </label>
        </div>
      </div>
      <div class="col-md-12" *ngIf="campo.tipoComponente === 'file'">
        <label for="{{ campo.nome }}" class="form-label">{{ campo.label }}</label>
        <input class="form-control" type="file" id="{{ campo.nome }}" name="{{ campo.nome }}" formControlName="{{ campo.nome }}">
      </div>
      <div class="col-md-12" *ngIf="campo.tipoComponente === 'select'">
        <label for="{{ campo.nome }}" class="form-label">{{ campo.label }}</label>
        <select class="form-select" aria-label="Default select example" id="{{ campo.nome }}" name="{{ campo.nome }}" formControlName="{{ campo.nome }}">
          <option selected>Selecione</option>
          
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
      <div class="col-md-12" *ngIf="campo.tipoComponente === 'textarea'">
        <label for="{{ campo.nome }}" class="form-label">{{ campo.label }}</label>
        <textarea formControlName="{{ campo.nome }}" class="form-control"  placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px" id="{{ campo.nome }}" name="{{ campo.nome }}"></textarea>
    </div>
    </ng-container>
  </ng-container>
  <ng-content select="[botoes]"></ng-content>
</form>

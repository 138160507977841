import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AcessoExterno } from '../models/domain/AcessoExterno';

@Injectable()
export class AcessoExternoService {
  private requerimentoUrl: string;
  private matricula: string;

  constructor(private http: HttpClient) {
    this.requerimentoUrl = environment.apiUrlRequerimento + '/acessoExterno/';
    //this.requerimentoUrl = 'http://localhost:8085/api/requerimento/';
  }

  public find(form: any): Observable<AcessoExterno[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<AcessoExterno[]>(this.requerimentoUrl + 'consulta', JSON.stringify(form), { headers: headers });
  }

  public save(dados: any): Observable<AcessoExterno[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<AcessoExterno[]>(this.requerimentoUrl + 'salvar', JSON.stringify(dados), { headers: headers });
  }

  public validToken(data: any): Observable<AcessoExterno[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<AcessoExterno[]>(this.requerimentoUrl + 'validarToken', JSON.stringify(data), { headers: headers });
  }

  public get(id: string): Observable<AcessoExterno> {
    return this.http.get<AcessoExterno>(this.requerimentoUrl + id);
  }

}

import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, inject, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { DialogConfirmacaoComponent } from 'src/app/core/components/gestao/datatable/dialogs/dialog-confirmacao/dialog-confirmacao.component';
import { Campo } from 'src/app/core/models/domain/Campo';
import { Grupo } from 'src/app/core/models/domain/Grupo';
import { Opcao } from 'src/app/core/models/domain/opcao';
import { ConfirmationDialogComponent } from '../../../../../utils/confirmation-dialog/confirmation-dialog.component';
import { getFormArray, getFormGroup } from '../../util/grupo-util';
import { getErrorMessage } from '../util/error-handler';

@Component({
  selector: 'app-campo-chamargrupo',
  templateUrl: './chamar-grupo.component.html',
  styleUrls: ['./chamar-grupo.component.css']
})
export class ChamarGrupoComponent implements OnInit {

  // Variáveis do modelo
  @Input()
  campo: Campo;

  @Input()
  grupoPai: Grupo;

  grupo: Grupo;

  @Input()
  stepFormGroup: FormGroup;

  @Input()
  grupoControlPai: FormGroup;

  gruposCriados: Grupo[] = [];

  // Controle do campo específico
  campoControl: FormControl = new FormControl('');

  // Autocomplete
  options: Opcao[];
  filteredGrupos: Observable<Opcao[]>;

  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;

  dialogRef = inject(MatDialogRef<DialogConfirmacaoComponent>);

  constructor(private formBuilder: FormBuilder, public router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.initAutoComplete();
    this.initGrupo();
  }

  initAutoComplete() {

    this.options = this.campo.opcoes;

    this.filteredGrupos = this.campoControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );

  }


  // Inicializa e adiciona o grupo multivalorado no formulário do passo
  initGrupo() { }

  // Método responsável por verificar qual mensagem deverá ser exibida par validação padrão
  getErrorMessage(): string {
    return getErrorMessage(this.campo, null, this.campoControl);
  }

  // Método para exibição no autocomplete
  displayFn(opcao: Opcao): string {
    return opcao?.label ? opcao.label : '';
  }

  // Filtragem no autocomplete
  private _filter(name: string): Opcao[] {

    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.valor.toLowerCase().includes(filterValue));

  }

  // Adiciona o grupo selecionado pelo usuário no formulário
  adicionarGrupo() {

    console.log(this.stepFormGroup);
    const opcao = this.campoControl.value as Opcao;
    const grupo = opcao.opcaoGrupo[0].grupo;

    const index = this.gruposCriados.indexOf(grupo);

    if (index == -1) {

      this.gruposCriados.push(grupo);

    } else {

      const panel = this.panels.toArray()[index];
      panel.open();

    }

  }

  excluirGrupo(grupo: Grupo) {

    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      disableClose: false,
      scrollStrategy: new NoopScrollStrategy()
    });

    this.dialogRef.componentInstance.alertMessage = "Todos os dados preenchidos até o momento serão perdidos.";
    this.dialogRef.componentInstance.confirmMessage = " Deseja realmente prosseguir com a exclusão do grupo " + grupo.label + " ?";

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const index = this.gruposCriados.indexOf(grupo);

        if (index == -1) {
          return;
        }

        // Removendo o painel da lista de paineis
        let panel = this.panels.toArray()[index] as MatExpansionPanel;
        panel._body.nativeElement.parentElement.remove();

        // Removendo do array dos grupos criados1
        this.gruposCriados.splice(index, 1);

        let formGroupPai = getFormGroup(this.stepFormGroup, this.grupoPai);
        let formGroup = getFormArray(formGroupPai, grupo.label);

        formGroupPai.removeControl(grupo.label);

      }

      this.dialogRef = null;
    });

  }

}

export const environment = {
    production: false,
    name: 'local environment',
    code: 1001,
    apiUrlUsuario: 'http://localhost:8084/api',
    apiUrlRequerimento: 'http://localhost:8085/api',
    apiUrlFile: 'http://localhost:8086/api',
    apiUrlFormulario: 'http://localhost:8083/api',
    apiUrlServidor: 'http://localhost:8082/api',
    apiUrlEmail: 'http://localhost:8087/api',
    secretKey: '29dc5d19-2a91-4cca-8721-3fa1bf8cfc8b',
    domainApi: 'localhost',
    domainApp: 'http://localhost:4200/'
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AcessoExternoService } from '../services/acesso-externo.service';

@Injectable({
  providedIn: 'root'
})
export class AcessoExternoGuard implements CanActivate {
  numeroProtocolo: any;
  token: any;
  email: any;

  constructor(
    private authenticationService: AcessoExternoService,
    private router: Router
  ) { }

  ngOnInit() {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    this.numeroProtocolo = route.queryParams['numeroProtocolo'];
    this.token = route.queryParams['token'];
    this.email = route.queryParams['email'];

    if (!this.numeroProtocolo || !this.token || !this.email) {
      this.router.navigate(['/acessoNegado']);
      return false;
    }

    const data = { idRequerimento: { numeroProtocolo: this.numeroProtocolo }, token: this.token, emailRequerente: this.email };

    return new Observable<boolean>(obs => {
      this.authenticationService.validToken(data).subscribe({

        next: (response) => {

          obs.next(true);

        },
        error: (e) => {

          switch (e.status) {
            case 403:
              this.router.navigate(['/acessoNegado']);
              console.log(e);
              break;
            default:
              this.router.navigate(['/erro']);
              console.log(e);
              break;
          }

          obs.next(false);

        }

      })
    });
  }

}

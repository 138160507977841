import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { UserResponse } from '../../core/models/authentication/responses/user-response';
import { PerfilResponse } from 'src/app/core/models/authentication/responses/perfil-response';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css']
})
export class DashComponent implements OnInit  {

   
  userResponse?: UserResponse | null;

  isAdmin : boolean = false;
  isDirecao : boolean = false;
  isPadrao : boolean = false;
  isChefia : boolean = false;



  constructor(private authenticationService: AuthenticationService) {
      this.authenticationService.userResponse.subscribe(x => this.userResponse = x);
  }

  logout() {
      this.authenticationService.logout();
  }

  ngOnInit(): void {

    if(this.userResponse!=null){
    const perfis: PerfilResponse[] =this.userResponse.perfis;
    this.isAdmin = perfis.some((papel) => papel.id === 1);
    this.isDirecao= perfis.some((papel) => papel.id === 2);
    this.isChefia = perfis.some((papel) => papel.id === 3);
    this.isPadrao = perfis.some((papel) => papel.id === 4);
    }
    
  }
}

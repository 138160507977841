<div id="files_{{ campo.nome }}" class="fileContainer">
  <div class="row">
    <div class="col-1 d-flex justify-content-center buttonInput">

      <button type="button" mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()"
        matTooltip="Selecionar Arquivo">
        <mat-icon>attach_file</mat-icon>
      </button>

      <input class="form-control file-input" type="file" id="{{ campo.nome }}" name="{{ campo.nome }}"
        [mask]="campo.mascara" [formControl]="formGroup" onclick="this.value = null"
        (change)="addFileToUpload($event, campo.nome, campo.idParent)" #fileUpload>

    </div>

    <div class="col-4">

      <span id="spanFile_{{ campo.nome }}" *ngIf="fileName" class="align-middle filename">
        {{fileName}}
      </span>
      <span id="spanFile_{{ campo.nome }}" *ngIf="!fileName" class="align-middle filename">
        Nenhum arquivo selecionado.
      </span>
      <div id="msg_error_{{ campo.nome }}" *ngIf="!fileName && campo.obrigatorio" class="error">
        <div>
          O campo {{ campo.label }} é obrigatório.
        </div>
      </div>

    </div>

    <div class="col-1" *ngIf="removivel">

      <button type="button" class="btn btn-action-requerimentos p-0 no-mobile"
        (click)="removeFile('files_' + campo.nome );">
        <i class="bi bi-trash3-fill text-danger buttonDeletar" matTooltip="Deletar campo"></i>
      </button>

    </div>

  </div>
</div>
<div class="container w-100" id="areaBusca">
    <form #formConsulta="ngForm" [formGroup]="reenvioForm" (ngSubmit)="onSubmit()"
        class="d-flex justify-content-center">
        <div class="card shadow w-50">
            <div class="card-body m-3">
                <div>
                    <p class="h3 fw-bold p-0">Reenvio de Acesso Externo / Token</p>
                </div>
                <div>
                    <p class="p-0">Preencha os dados para reenvio de acesso pro usuário externo:</p>
                </div>
                <div class="alert alert-success" *ngIf="msgSuccess" role="alert">
                    Envio realizado com sucesso! <br> Em instantes o usuário receberá um email com o link para acesso.
                </div>
                <div class="parent">

                    <mat-form-field appearance="outline" class="full-width">

                        <mat-label>Nº Protocolo</mat-label>
                        <input matInput id="numeroProtocolo" name="numeroProtocolo" formControlName="numeroProtocolo"
                            (blur)="validarNumeroProtocolo($event)" minlength="7" maxlength="8" />

                        <!-- Errors -->
                        <mat-error *ngIf="!reenvioForm.value.numeroProtocolo">Por favor, informe o número do
                            Protocolo.</mat-error>
                        <mat-error *ngIf="reenvioForm.controls.numeroProtocolo.hasError('minlength')">O
                            campo Nº Protocolo
                            deverá conter 8 caracteres.</mat-error>
                        <mat-error *ngIf="reenvioForm.controls.numeroProtocolo.hasError('errorNumeroProtocolo')">
                            {{ msgFailureNumeroProtocolo }}
                        </mat-error>

                    </mat-form-field>

                </div>
                <div class="parent">

                    <mat-form-field appearance="outline" class="full-width">

                        <mat-label>Email</mat-label>
                        <input matInput id="email" name="email" formControlName="email" type="email" />

                        <!-- Errors -->
                        <mat-error *ngIf="!reenvioForm.value.email">Por favor, informe o e-mail.</mat-error>
                        <mat-error *ngIf="reenvioForm.value.email">Email inválido.</mat-error>

                    </mat-form-field>

                </div>
                <div class="parent">

                    <mat-form-field appearance="outline" class="full-width">

                        <mat-label>CPF</mat-label>
                        <input matInput id="cpf" name="cpf" formControlName="cpf" mask="000.000.000-00"
                            minlength="11" />

                        <!-- Errors -->
                        <mat-error *ngIf="!reenvioForm.value.cpf">Por favor, informe o cpf.</mat-error>
                        <mat-error *ngIf="reenvioForm.controls.cpf.hasError('minlength')">O campo CPF deverá conter 11
                            dígitos.</mat-error>

                    </mat-form-field>

                </div>
                <div class="parent">
                    <mat-error>{{ msgFailure }}</mat-error>
                </div>
                <div class="text-center p-2">
                    <div class="col-12">
                        <button mat-flat-button class="me-3" color="primary" type="submit"
                            [disabled]="!reenvioForm.valid || !numeroProtocoloValido">Enviar Acesso (Token)</button>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#3f51b5" type="ball-atom" name="tokenSpinner">
    <p style="font-size: 20px; color: white">{{ textoTokenSpinner }}</p>
</ngx-spinner>
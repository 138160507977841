import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogConfirmacaoComponent } from 'src/app/core/components/gestao/datatable/dialogs/dialog-confirmacao/dialog-confirmacao.component';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';
import Swal from 'sweetalert2';
import { ConfirmationDialogComponent } from '../../utils/confirmation-dialog/confirmation-dialog.component';
import { GenericDTO } from './models/generic-dto';
import { GrupoConclusao } from './models/grupo-conclusao';

@Component({
  selector: 'app-step-conclusao-abertura-requerimento',
  templateUrl: './step-conclusao.component.html',
  styleUrls: ['./step-conclusao.component.css']
})
export class StepConclusaoComponent implements OnInit {

  @Input()
  grupos: GrupoConclusao[];

  dialogRef = inject(MatDialogRef<DialogConfirmacaoComponent>);

  @Input()
  tipoRequerimento: GenericDTO;

  @Input()
  tipoOperacao: GenericDTO;

  @Input()
  matricula: Number;

  @Input()
  servidor: boolean;

  @Input()
  formData = new FormData();

  messageError: String;


  readonly nameSpinner: string = 'conclusaoSpinner';

  constructor(private router: Router, public dialog: MatDialog, private requerimentoService: RequerimentoService, private ngxSpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {

  }

  confirmarCriacaoRequerimento() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      disableClose: false,
      scrollStrategy: new NoopScrollStrategy()
    });

    this.dialogRef.componentInstance.alertMessage = "Certifique-se que os dados estão preenchidos corretamente junto com os anexos pois quando criado só poderá ser excluído para criar um outro requerimento.";
    this.dialogRef.componentInstance.confirmMessage = "Deseja continuar com a criação do Requerimento?";

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.ngxSpinnerService.show(this.nameSpinner);

        let cpf = this.getValor('CPF');
        let nomeRequerente = this.getValor('Nome');
        let emailRequerente = this.getValor('E-mail');

        let json = {
          "cpfRequerente": cpf,
          "cpfRequisitado": cpf,
          "cpfAtendente": cpf,
          "tipoRequerimentoDto": this.tipoRequerimento,
          "nomeRequerente": nomeRequerente,
          "emailRequerente": emailRequerente,
          "tipoOperacaoDTO": this.tipoOperacao,
          "matriculaRequisitado": this.matricula,
          "servidor": this.servidor,
          "gruposDTO": this.grupos
        };

        this.formData.set("aberturaDTO", new Blob([JSON.stringify(json)], { type: "application/json" }));

        this.requerimentoService.abertura(this.formData).subscribe({

          next: (data) => {
            this.ngxSpinnerService.hide(this.nameSpinner);
            console.log(data);

            Swal.fire(
              'Parabéns!!',
              'Requerimento criado com sucesso',
              'success'
            )

            this.router.navigate(['/abertura', 'conclusao', data.numeroProtocolo]);

          },
          error: (err) => {

            this.ngxSpinnerService.hide(this.nameSpinner);
            this.messageError = 'Erro ao salvar o Requerimento.';

            this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              width: '500px',
              disableClose: false,
              scrollStrategy: new NoopScrollStrategy()
            });

            this.dialogRef.componentInstance.titulo = "Erro ao Salvar"
            this.dialogRef.componentInstance.errorMessage = "Não foi possível salvar o Requerimento.";
            this.dialogRef.componentInstance.readOnly = true;

            console.log(err);
          }

        });

      }

      this.dialogRef = null;
    });
  }

  getValor(labelCampo: string): string {

    let valor = '';

    for (let g of this.grupos) {

      let valores = g.campos.find(c => {
        return labelCampo === c.label
      })?.valor;

      if (valores) {
        let valorFinal = '';
        valores.forEach(v => {
          valorFinal = (valorFinal) ? valorFinal + ',' + v.valor : v.valor;
        });
        valor = valorFinal;
        break;
      }

    }

    return valor;

  }

}

import { CampoEnum } from "src/app/core/components/requerimento/abertura/stepper/step/grupo/campo/util/campo-enum";
import { TipoComponenteAppEnum } from "src/app/core/components/requerimento/abertura/utils/tipo-componente-app-enum";

export class FormControlMetadados {

    nome: string = '';
    label: string = '';
    tipoComponente: CampoEnum;
    multiValorado: boolean = false;
    tipoComponenteAppEnum: TipoComponenteAppEnum;
    valor: string;
    obrigatorio: boolean;
    ordem: number;
    tipoLayout: number;

    constructor(nome: string, label: string, tipoComponente: CampoEnum, tipoComponenteAppEnum: TipoComponenteAppEnum, multiValorado: boolean,
        valor: string, obrigatorio: boolean, ordem: number, tipoLayout: number) {
        this.nome = nome;
        this.label = label;
        this.tipoComponente = tipoComponente;
        this.multiValorado = multiValorado;
        this.tipoComponenteAppEnum = tipoComponenteAppEnum;
        this.valor = valor;
        this.obrigatorio = obrigatorio;
        this.ordem = ordem;
        this.tipoLayout = tipoLayout;
    }

}

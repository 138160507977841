import { TipoOperacao } from "./TipoOperacao";

export class TipoRequerimento {
    private _id: number;
    private idTipo: number;
    private _nome: string;
    private _tipoRequerimentoPai: TipoRequerimento;
    private _visivel: boolean; // Note que mudei o nome da propriedade para _visivel
    private _tipoOperacoes: TipoOperacao[];
    private _gruposTipoRequerimento: any;
    private _tipoRequerimentoFilhos: TipoRequerimento[];

    constructor(
        id: number,
        idTipo: number,
        nome: string,
        visivel: boolean,
        operacoes: TipoOperacao[],
        gruposTipoRequerimento: any,
        tipoRequerimentoFilhos: TipoRequerimento[]
    ) {
        this.id = id;
        this.idTipo = idTipo;
        this.nome = nome;
        this.visivel = visivel;
        this._tipoOperacoes = operacoes;
        this.gruposTipoRequerimento = gruposTipoRequerimento;
        this.tipoRequerimentoFilhos = tipoRequerimentoFilhos;
    }

    public get nome(): string {
        return this._nome;
    }

    public set nome(value: string) {
        this._nome = value;
    }

    public get id(): number {
        return this._id;
    }

    public set id(value: number) {
        this._id = value;
    }

    public set gruposTipoRequerimento(value: any) {
        this._gruposTipoRequerimento = value;
    }

    public get gruposTipoRequerimento(): any {
        return this._gruposTipoRequerimento;
    }

    public set tipoRequerimentoFilhos(value: TipoRequerimento[]) {
        this._tipoRequerimentoFilhos = value;
    }

    public get tipoRequerimentoFilhos(): TipoRequerimento[] {
        return this._tipoRequerimentoFilhos;
    }

    public set tipoRequerimentoPai(value: TipoRequerimento) {
        this.tipoRequerimentoPai = value;
    }

    public get tipoRequerimentoPai(): TipoRequerimento {
        return this.tipoRequerimentoPai;
    }

    public get visivel(): boolean {
        return this._visivel;
    }

    public set visivel(value: boolean) {
        this._visivel = value;
    }

    public get tipoOperacoes(): TipoOperacao[] {
        return this._tipoOperacoes;
    }

    public set tipoOperacoes(value: TipoOperacao[]) {
        this._tipoOperacoes = value;
    }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AcessoExternoService } from 'src/app/core/services/acesso-externo.service';
@Component({
  selector: 'app-index',
  templateUrl: './consulta-externa.component.html',
  styleUrls: ['./consulta-externa.component.css']
})
export class ConsultaExterna implements OnInit {

  formularioConsulta = new FormGroup({
    emailRequerente: new FormControl('', [Validators.required, Validators.email]),
    token: new FormControl('', [Validators.required, Validators.minLength(36)]),
    numeroProtocolo: new FormControl('', [Validators.required])
  });
  mostrarErroNoFormulario: boolean;

  constructor(
    private acessoExternoService: AcessoExternoService, private router: Router
  ) {
  }

  get form(): any {
    return this.formularioConsulta.controls;
  }

  ngOnInit(): void {


  }

  buscarRequerimento() {

    this.router.navigate(['/requerimento/visualizar'],
      {
        queryParams: {
          numeroProtocolo: this.formularioConsulta.controls.numeroProtocolo.value,
          token: this.formularioConsulta.controls.token.value, email: this.formularioConsulta.controls.emailRequerente.value
        }
      });

  }

  limpar() {
    this.formularioConsulta.reset();
  }

}

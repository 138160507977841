import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { AuthenticationService } from './core/services/authentication.service';
import { UserResponse } from './core/models/authentication/responses/user-response';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  userResponse?: UserResponse | null;
  curPath = "/";
  useDash = true;

  pathsWithoutDash = ["/filters", "/tabela", "/admin", "/login"];

  constructor(private authenticationService: AuthenticationService, private router: Router) {
      this.authenticationService.userResponse.subscribe(x => this.userResponse = x);
      this.router.events.subscribe(ev => {
        if (!(ev instanceof NavigationEnd))
          return;
        this.curPath = ev.urlAfterRedirects;
        this.useDash = !this.pathsWithoutDash.includes(this.curPath);
      });
    }

  logout() {
      this.authenticationService.logout();
  }
  
  title = 'Entrar';
}

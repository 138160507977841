<mat-card style="padding: 20px;">
  <mat-card-head class="card-header">
    <mat-card-title>
      Abertura de Requerimento
    </mat-card-title>
    <mat-card-subtitle>
      Selecione as opções abaixo para inclusão do Requerimento:
    </mat-card-subtitle>
  </mat-card-head>
  <mat-card-content style="text-align: center;">
    <form id="requerimentoFormGroup" [formGroup]="requerimentoFormGroup" class="form-full-width" (ngSubmit)="submit()">
      <div class="parent">
        <mat-form-field class="input-width" appearance="outline" [hidden]="!papeis">
          <mat-label>Matrícula / Cargo</mat-label>
          <div style="text-align:center;">
            <mat-select formControlName="matricula" (selectionChange)="listarTiposRequerimento()" [disabled]="!papeis">
              <mat-option *ngFor="let papel of papeis" [value]="papel">
                <span>{{papel.matricula}}</span>
                <span *ngIf="papel.matricula">/</span>
                <span>{{papel.nome_papel}}</span>
              </mat-option>
            </mat-select>
          </div>
          <mat-error>Selecione uma Matrícula</mat-error>
        </mat-form-field>
      </div>
      <div class="parent">
        <mat-form-field class="input-width" appearance="outline">
          <mat-label>Tipo de Requerimento</mat-label>
          <input type="text" matInput formControlName="tipoRequerimento" [matAutocomplete]="auto" class="text-center">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="listarSubGruposTipoRequerimento()">
            <mat-option *ngFor="let requerimento of requerimentosFiltrados | async" [value]="requerimento">
              {{requerimento.nome}}
            </mat-option>
          </mat-autocomplete>

          <!-- Errors -->
          <mat-error>Selecione um tipo de Requerimento</mat-error>

        </mat-form-field>
      </div>
      <div class="parent">
        <mat-form-field class="input-width" appearance="outline" [hidden]="!temSubGrupos">
          <mat-label>Subgrupo de Requerimento</mat-label>
          <div style="text-align:center;">
            <mat-select formControlName="subGrupoRequerimento" (selectionChange)="listarTipoOperacoes(null)"
              [disabled]="tipoRequerimentoFormControl.invalid || !temSubGrupos">
              <mat-option *ngFor="let subGrupo of subGruposTipoRequerimento" [value]="subGrupo">
                <span *ngIf="subGrupo.visivel">{{subGrupo.nome}}</span>
              </mat-option>
            </mat-select>
          </div>
          <mat-error *ngIf="subGrupoRequerimentoFormControl.invalid">Selecione um Subgrupo</mat-error>
        </mat-form-field>
      </div>
      <div class="parent">
        <mat-form-field class="input-width" appearance="outline">
          <mat-label>Operação</mat-label>
          <div style="text-align:center;">
            <mat-select formControlName="tipoOperacao"
              [disabled]="!requerimentoFormGroup.controls.subGrupoRequerimento.value || tiposOperacao?.length == 1">
              <mat-option *ngFor="let tipoOperacao of tiposOperacao" [value]="tipoOperacao">
                <span>{{tipoOperacao.nome}}</span>
              </mat-option>
            </mat-select>
          </div>
          <mat-error>Selecione uma Operação</mat-error>
        </mat-form-field>
      </div>
    </form>
    <mat-divider></mat-divider>
  </mat-card-content>
  <div class="parent">
    <mat-card-actions>
      <div style="text-align: center;">
        <button form="requerimentoFormGroup" mat-raised-button color="primary"
          [disabled]="!requerimentoFormGroup.touched || requerimentoFormGroup.invalid" disabled>Próximo</button>
      </div>
    </mat-card-actions>
  </div>
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <mat-error class="errorMessage"> {{ errorMessage }}</mat-error>
  </mat-card-footer>
</mat-card>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#3f51b5" type="ball-atom" name="inicioSpinner">
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#3f51b5" type="ball-atom" name="formularioSpinner">
  <p style="font-size: 20px; color: white">Carregando {{ textoFormularioSpinner }} ...
  </p>
</ngx-spinner>
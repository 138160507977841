import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }
  
  //converte tudo para json e criptografa
  public encrypt(data: any){
    let encryptJSON = CryptoJS.AES.encrypt(JSON.stringify(data), environment.secretKey).toString();
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptJSON));
  }

  //descriptografa e converte para o objeto em questão
  public decrypt(data: string, classType: any){

    let jsonData = CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
    let bytes = CryptoJS.AES.decrypt(jsonData, environment.secretKey).toString(CryptoJS.enc.Utf8);

    return new classType(JSON.parse(bytes));
  }
}

import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Grupo } from '../../models/domain/Grupo';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-campos-form',
  templateUrl: './campos-form.component.html',
  styleUrls: ['./campos-form.component.css']
})
export class CamposFormComponent implements OnInit, AfterViewInit {

  @Input() grupos: Array<Grupo>;

  
  @Input() refName: string;
  @Input() refFormGroupName: string;
  
  formGroupName: FormGroup;

  formRequerimento: FormGroup;
  formDadosServidor: FormGroup;
  formDadosDocumentoServidor: FormGroup;
  formDadosVisualizacao: FormGroup;

  //formulários
  @ViewChild('refFormDadosServidor') refFormDadosServidor: ElementRef;
  @ViewChild('refFormDadosInformacoesAdicionais') refFormDadosInformacoesAdicionais: ElementRef;
  @ViewChild('refFormDadosDocumentoServidor') refFormDadosDocumentoServidor: ElementRef;
  @ViewChild('refFormDadosCiencia') refFormDadosCiencia: ElementRef;
  @ViewChild('refFormVisualizacao') refFormVisualizacao: ElementRef;

  //grupos de preenchimentos do step/step
  @ViewChild('dadosServidor') dadosServidor: ElementRef;
  @ViewChild('tipoRequerimento') tipoRequerimento: ElementRef;
  @ViewChild('outrasInformacoes') outrasInformacoes: ElementRef;
  @ViewChild('documentos') documentos: ElementRef;
  @ViewChild('ciencias') ciencias: ElementRef;
  @ViewChild('conclusao') conclusao: ElementRef;

  constructor(private formBuilder: FormBuilder){
    this.formDadosServidor = this.formBuilder.group({
      
    });
    this.formDadosVisualizacao = this.formBuilder.group({
      
    });
    this.formDadosDocumentoServidor = this.formBuilder.group({
      
    });

    if(this.refFormGroupName == 'formDadosServidor') {
      this.formGroupName = this.formDadosServidor
    }

    if(this.refFormGroupName == 'formDadosDocumentoServidor') {
      this.formGroupName = this.formDadosDocumentoServidor
    }

    if(this.refFormGroupName == 'formDadosVisualizacao') {
      this.formGroupName = this.formDadosVisualizacao
    }

  }

  ngOnInit(): void {
    console.log('ngOnInit');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');
  
  }

  getForm(nome: String) : FormGroup {
    switch(nome) {
      case "formRequerimento":
        return this.formRequerimento;
      case "formDadosServidor":
        return this.formDadosServidor;
      case "formDadosDocumentoServidor":
        return this.formDadosDocumentoServidor;
      case "formDadosVisualizacao":
          return this.formDadosVisualizacao;
      default:
        return null;
    }
  }

  filtraGrupoPasso(id: number) : any {
    return this.grupos?.filter(e => e.idPasso == id);
  }

  addCamposFormularios(grupos: any){
    //populando os campos de dados funcionais
    if(this.grupos.length){ 
      if(this.filtraGrupoPasso(2).length > 0) {
        for (const grupo of this.filtraGrupoPasso(2)) {
          for (const campo of grupo?.camposGrupos) {
            this.formDadosServidor.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
            this.formDadosVisualizacao.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
          }
        }
      }
      
      if(this.filtraGrupoPasso(3).length > 0) {
        for (const grupo of this.filtraGrupoPasso(3)) {
          for (const campo of grupo?.camposGrupos) {
            this.formDadosServidor.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
            this.formDadosVisualizacao.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
          }
        }
      }

      //populando os campos de documentos
      if(this.filtraGrupoPasso(4).length > 0) {
        for (const grupo of this.filtraGrupoPasso(4)) {
          for (const campo of grupo?.camposGrupos) {
            this.formDadosServidor.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
            this.formDadosVisualizacao.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
          }
        }
      }

      if(this.filtraGrupoPasso(5).length > 0) {
        for (const grupo of this.filtraGrupoPasso(5)) {
          for (const campo of grupo?.camposGrupos) {
            this.formDadosServidor.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
            this.formDadosVisualizacao.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
          }
        }
      }
    }
  }

  private getValidator(formField: any): ValidatorFn{
    switch(formField.validator) {
      case "email":
        return Validators.email;
      case "required":
        return Validators.required;
      case "file":
        return Validators.required;
      default:
        return null;
    }
  }


}


export class ValorConclusao {

    valor: string;
    text: string;

    constructor(valor: string, text: string) {
        this.text = text;
        this.valor = valor;
    }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormControlMetadados } from './models/form-control-metadados';

declare module '@angular/forms' {

  export interface AbstractControl {
    metaDados: FormControlMetadados
  }

}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CustomFormModule { }

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { error } from 'jquery';
import { Requerimento } from 'src/app/core/models/domain/Requerimento';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';
import { aplicarMascaraSei } from 'src/app/core/components/requerimento/abertura/stepper/step/grupo/campo/util/campo-util';



@Component({
  selector: 'app-dialog-edicao',
  templateUrl: './dialog-edicao.component.html',
  styleUrls: ['./dialog-edicao.component.css']
})
export class DialogEdicaoComponent implements OnInit {

  id: string;
  numeroSEI: string;
  result: Boolean;
  
  constructor(private requerimentoService: RequerimentoService, private http: HttpClient, public dialogRef: MatDialogRef<DialogEdicaoComponent>){


    
  }

  ngOnInit(): void {

  }

  editarNumeroSEI() {

    if (this.numeroSEI === undefined || !this.numeroSEI || this.numeroSEI.trim() === '') {
      this.numeroSEI = "";
      console.log("Número SEI não está definido.");
      return; // Ou qualquer ação que você deseja realizar se o número SEI for undefined
    } else {

    const body = {
      id: this.id,
      numeroSEI: aplicarMascaraSei(this.numeroSEI),
    }

    const bodyPesquisa = {
      numeroSEI: aplicarMascaraSei(this.numeroSEI),
      
    }

    if(confirm(" Deseja alterar o número SEI para:  "+body.numeroSEI+ "?")) {
      this.requerimentoService.search(bodyPesquisa).subscribe(data => {
        //this.requerimentos = data;
        if(data.length == 0){
        this.requerimentoService.update(body).subscribe({});
        this.result = true;
        }else{
          this.result = false;
        }
  
        this.dialogRef.close(this.result);
  
      });

    }

  }


    //const requerimento: any =  this.requerimentoService.search(bodyPesquisa);
    //console.log(requerimento);

    

  }


}

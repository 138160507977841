<div class="card text-center step-card">
  <div class="card-header bg-primary header">
    <p class="text-white h4">
      {{tipoOperacao.nome}} de {{tipoRequerimento?.nome}} {{(subGrupoRequerimento) ? ' > ' +
      subGrupoRequerimento?.nome : ''}}
    </p>
  </div>
  <div class="card-body">
    <mat-progress-spinner mode="indeterminate" *ngIf="!steps"></mat-progress-spinner>
    <mat-stepper *ngIf="steps?.length > 0" [orientation]="orientation" [linear]="isLinear" #stepper>
      <mat-step [stepControl]="stepapp.stepFormGroup" *ngFor="let step of steps; index as i">
        <mat-step-header role="tab" aria-selected="false">
          <ng-template matStepLabel="none">
            <div class="step-icon-wrap">
              <div class="step-icon">
                <img
                  [src]="'/assets/icons/' + (i <= stepper.selectedIndex ? 'activated/' : '') + '2ico_' + step.nome + '.png'"
                  class="icone-step" [ngClass]="{'mat-completed' : (i < stepper.selectedIndex)}">
              </div>
            </div>
            <div class="step-title">
              <h4>{{step.label}}</h4>
            </div>

          </ng-template>
        </mat-step-header>
        <app-step [step]="step" [dadosServidor]="dadosServidor" [primeiro]="i == 0" [ultimo]="(steps?.length == i + 1)"
          (onCreationStepFormGroup)="adicionarStepFormGroup($event)" (onConclusao)="concluirPreenchimento()"
          #stepapp></app-step>
      </mat-step>
      <mat-step>
        <mat-step-header role="tab" aria-selected="false">
          <ng-template matStepLabel="none">
            <div class="step-icon-wrap">
              <div class="step-icon">
                <img [src]="'/assets/icons/2ico_' + 'conclusao' + '.png'" class="icone-step">
              </div>
            </div>
            <div class="step-title">
              <h4>Conclusão</h4>
            </div>

          </ng-template>
        </mat-step-header>
        <ng-template matStepLabel>Step 1</ng-template>
        <ng-template matStepContent>
          <app-step-conclusao-abertura-requerimento [grupos]="grupos" [formData]="formData"
            [tipoRequerimento]="(subGrupoRequerimento) ? subGrupoRequerimento : tipoRequerimento"
            [tipoOperacao]="tipoOperacao" [matricula]="matricula"
            [servidor]="dadosServidor ? true : false"></app-step-conclusao-abertura-requerimento>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
  <div class="card-footer mt-4">
    <button mat-raised-button color="primary" (click)="confirmarReinicializacao();$event.preventDefault();">
    Reiniciar Preenchimento
    </button>
  </div>
  <!--
  <div class="card-footer text-muted">
    <a href="#" style="font-size: 16px;" (click)="confirmarReinicializacao();$event.preventDefault();">Reiniciar
      Preenchimento</a>
  </div>
  -->
</div>
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CryptoService } from "../services/crypto.service";
import { AuthenticationResponse } from "../models/authentication/responses/authentication-response";
import { PapelResponse } from "../models/authentication/responses/papel-response";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationHelper {

    constructor(private cryptoService: CryptoService){

    }

    isAdmin(): boolean{

        const jsonToken = localStorage.getItem("token");
        let authenticationResponse = this.cryptoService.decrypt(jsonToken, AuthenticationResponse);

        let roles = authenticationResponse.userResponse.perfis;

        return roles && roles.find((role: any) => role.nome == 'admin');

    }

    isPadrao(): boolean {
        const jsonToken = localStorage.getItem("token");
        let authenticationResponse = this.cryptoService.decrypt(jsonToken, AuthenticationResponse);
    
        let roles = authenticationResponse.userResponse.perfis;
    
        return !!roles && roles.some((role: any) => role.nome === 'padrao');
    }

    logado(): boolean {

        const jsonToken = localStorage.getItem("token");
        let roles = null;

        if(jsonToken){
            let authenticationResponse = this.cryptoService.decrypt(jsonToken, AuthenticationResponse);
        
            roles = authenticationResponse.userResponse.perfis;
        }
    
        return roles?.length > 0;

    }

    getPapeis(): Array<PapelResponse> {
        
        const jsonToken = localStorage.getItem("token");

        if(jsonToken){
            let authenticationResponse = this.cryptoService.decrypt(jsonToken, AuthenticationResponse);
        
            return authenticationResponse.userResponse.papeis;
        }
    
        return null;

    }

}
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Campo } from "src/app/core/models/domain/Campo";
import { Grupo } from "src/app/core/models/domain/Grupo";

/*
    Funções para obtenção dos formulários e verficação do grupo
*/

export function getFormArray(form: FormGroup, key: string): FormArray {
    return form.controls[key] as FormArray;
}

export function getFormGroup(form: FormGroup, grupo: Grupo): FormGroup {
    return (!grupo.multiValorado) ? (form.controls[grupo.label] as FormGroup) : getFirstFormGroup(form, grupo.label);
}

export function getFormControl(form: FormGroup, campo: Campo): FormControl {
    return form.controls[campo.nome] as FormControl;
}

function getFirstFormGroup(form: FormGroup, key: string): FormGroup {
    return (form.controls[key] as FormArray).at(0) as FormGroup;
}

export function isMultivalorado(grupo: Grupo): boolean {
    return grupo.multiValorado;
}
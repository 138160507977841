<div class="container padding-bottom-3x mb-1">
  <div class="card mb-3 shadow">
    <div *ngIf="requerimento!=null" class="p-4 text-center text-white text-lg bg-success rounded-top"><span
        class="text-uppercase">N° do
        requerimento - </span><span class="text-medium"><strong *ngIf="requerimento?.numeroProtocolo"># {{
          requerimento?.numeroProtocolo }} </strong></span></div>

    <!-- criação do novo componente -->
    <div class="card-body mt-4" *ngIf="!requerimentoaberto">
      <div class="container h-100" *ngIf="isPrimeiroPasso" id="titulorequerimento">
        <div class="row align-items-center h-100">
          <div class="col-md-6 mx-auto">
            <form #refFormRequerimento="ngForm" [formGroup]="formRequerimento"
              class="row g-3 needs-validation form-inline mb-5" novalidate>
              <div class="d-flex flex-column justify-content-center mt-4">
                <div class="col-md-4- text-center">
                  <label for="requerimento" class="form-label h3 p-3">Requerimento</label>
                  <div class="flex-row text-center" *ngIf="logado">
                    <p>Selecione a Matrícula / Cargo</p>

                    <select formControlName="matricula" class="form-select" id="matricula" name="matricula" required
                      (change)="changePapel($event)">
                      <option *ngFor="let papel of papeis" [value]="papel" [attr.data-id]="papel.id"
                        [attr.data-matricula]="papel.matricula">{{papel.matricula + ((papel.matricula ) ? ' / ' : '') +
                        papel.nome_papel }}</option>
                    </select>
                  </div>
                  <div class="flex-row mt-3" *ngIf="listaRequerimentos">
                    <p>Selecione o Tipo de Requerimento</p>
                    <select formControlName="requerimento" class="form-select" id="requerimento" name="requerimento"
                      required (change)="changeRequerimento($event)">
                      <option selected disabled value="">Lista de requerimentos disponíveis</option>
                      <option *ngFor="let requerimento of listaRequerimentos" [value]="requerimento.id"
                        [attr.data-value]="requerimento.id">{{requerimento.nome}}</option>
                    </select>
                  </div>
                  <div class="invalid-feedback">
                    Erro ao selecionar o requerimento
                  </div>
                </div>
                <div class="col-md-4- text-center" *ngIf="temFilhos" id="subgruporequerimento">
                  <label for="requerimento" class="p-4" style="font-size:18px;">Subgrupo do
                    requerimento</label><!-- p-3 form-label fw-bold -->
                  <select formControlName="requerimentoFilho" class="form-select" id="requerimentoFilho"
                    name="requerimentoFilho" required (change)="changeRequerimentoFilho($event)">
                    <option selected disabled value="">Lista de requerimentos disponíveis</option>
                    <option *ngFor="let requerimento of listaRequerimentosFilhos" [value]="requerimento.id"
                      [attr.data-value]="requerimento.id">{{requerimento.nome}}</option>
                  </select>
                  <div class="invalid-feedback">
                    Erro ao selecionar o requerimento
                  </div>
                </div>
                <div class="flex-row"></div>
                <div class="col-md-4- text-center" *ngIf="temOperacoes">
                  <label for="requerimento" class="p-4" style="font-size:18px;">Operação</label>
                  <select formControlName="operacao" class="form-select" id="operacao" name="operacao" required
                    (change)="changeOperacao($event)">
                    <option selected disabled value="">Lista de Operacoes</option>
                    <option *ngFor="let operacao of listaOperacoes" [value]="operacao.id"
                      [attr.data-value]="operacao.id">{{operacao.nome}}</option>
                  </select>
                  <div class="invalid-feedback">
                    Erro ao selecionar a operação
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <form *ngIf="filterJsonFormRequerimento" [formGroup]="filterJsonFormRequerimento" class="filter-form"
        id="formRequerimento">
        <ng-container
          *ngTemplateOutlet="recursiveList; context:{ $implicit: jsonRequerimentoPassos, formGroup:filterJsonFormRequerimento }"></ng-container>
      </form>

      <!--  monta o formulário para preenchimento por passos -->
      <ng-template #recursiveList let-filterFields let-formGroup="formGroup">
        <ng-container *ngFor="let item of filterFields">
          <ng-container *ngFor="let operacaoStep of item.stepper.operacoes">
            <ng-container *ngIf="operacaoSelecionada !=undefined && operacaoStep.id == operacaoSelecionada">

              <div class="p-4 text-center text-white text-lg rounded-top tituloreqbacktopo">
                <h1 class="text-center" style="margin:auto;">
                  <!--{{ operacaoStep.nome }} <br> Licença-prêmio > Abertura de Processo-->
                  {{tipoRequerimentoSelecionadoNome}}
                  <span *ngIf="tipoRequerimentoSelecionadoNomeFilho"> > {{tipoRequerimentoSelecionadoNomeFilho}}</span>
                </h1>
              </div>

              <div role="tablist" id="v-pills-tab-{{operacaoStep.id}}"
                class="nav nav-pills steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x"
                style="margin-top: 50px;">
                <ng-container *ngFor="let passo of operacaoStep.steps; let itab = index">
                  <!-- ITENS DINÂMICOS: PASSOS EXIBIDOS DE ACORDO COM O TIPO DE REQUERIMENTO SELECIONADO  -->
                  <div [ngClass]="{'completed': passoAtivo >= passo.stepNumber || itab == 0}" class="step">
                    <div class="step-icon-wrap">
                      <div class="step-icon">
                        <button href="javascript:void(0);" (click)="passoSelecionado(passo.stepNumber)"
                          [ngClass]="[itab == 0 ? 'active' : '']" class="active-"
                          id="v-requerimento-{{ operacaoStep.id }}-{{passo.nome}}-tab" data-bs-toggle="pill"
                          attr.data-bs-target="#v-pills-requerimento-dados-{{ operacaoStep.id }}-{{passo.nome}}"
                          type="button" role="tab"
                          attr.aria-controls="v-pills-requerimento-dados-{{ operacaoStep.id }}-{{passo.nome}}"
                          aria-selected="true">

                          <div *ngIf="passo.stepNumber =='1'">
                            <img src="/assets/icons2ico_lgpd.png" class="icone-step">
                          </div>

                          <div *ngIf="passo.stepNumber =='2'">
                            <img src="/assets/icons/2ico_dadosservidor.png" class="icone-step">
                          </div>

                          <div *ngIf="passo.stepNumber =='3'">
                            <img src="/assets/icons/2ico_dadosadicionais.png" class="icone-step">
                          </div>

                          <div *ngIf="passo.stepNumber =='4'">
                            <img src="/assets/icons/2ico_documentacao.png" class="icone-step">
                          </div>

                          <div *ngIf="passo.stepNumber =='5'">
                            <img src="/assets/icons/2ico_ciencias.png" class="icone-step">
                          </div>

                        </button>
                      </div>
                    </div>
                    <h4 class="step-title">{{ passo.label }}</h4>
                  </div>
                </ng-container>
                <!-- ITEM FIXO: CONCLUIR PREENCHIMENTO -->
                <div [ngClass]="{'completed': passoAtivo >= 99}" class="step">
                  <div class="step-icon-wrap">
                    <div class="step-icon">
                      <button #conclusao [disabled]="false" href="javascript:void(0);" (click)="passoSelecionado('99')"
                        class="active-" id="v-pills-requerimento-{{operacaoStep.id}}-tab" data-bs-toggle="pill"
                        attr.data-bs-target="#v-pills-requerimento-{{operacaoStep.id}}-conclusao" type="button"
                        role="tab" attr.aria-controls="v-pills-requerimento-{{operacaoStep.id}}-conclusao"
                        aria-selected="true">
                        <img src="/assets/icons/2ico_conclusao.png" class="icone-step">
                      </button>
                    </div>
                  </div>
                  <h4 class="step-title">Conclusão</h4>
                </div>
              </div>
              <!-- GRUPOS DE CAMPOS DINÂMICOS EXIBIDOS PARA CADA PASSO -->
              <div class="tab-content" id="v-pills-tabRequerimento">
                <ng-container *ngFor="let operacao of item.stepper.operacoes; let i = index">
                  <ng-container *ngIf="operacaoStep.id == operacao.id">
                    <ng-container *ngFor="let passo of operacao.steps; let i = index">

                      <!-- FORMULÁRIO RENDERIZADO DINAMICAMENTE -->
                      <div class="tab-pane fade show active--" [ngClass]="[i == 0 ? 'active' : '']"
                        id="v-pills-requerimento-dados-{{ operacao.id }}-{{passo.nome}}" role="tabpanel"
                        attr.aria-labelledby="v-pills-requerimento-{{ operacao.id }}-{{passo.nome}}-tab" tabindex="0">

                        <div class="alert alert-warning w-50" role="alert" *ngIf="passo.nome == 'documentos'">
                          Documentos que possuem <strong>frente e verso</strong>, anexar <strong>ambos.</strong>
                        </div>

                        <ng-container *ngFor="let grupo of passo.grupos">

                          <div id="grupo_{{ grupo.nome }}" [hidden]="!grupo.visivel"
                            [ngClass]="grupo.grupoPai ? 'grupoPai_' + grupo.grupoPai.id : ''">
                            <!--Barra sob o titulo-->
                            <div class="divhr">
                              <p class="fs-4 text-center pt05rem corTituloSobBarra">{{grupo.label}}</p>
                              <!--Cor da fonte titulo sob barra-->
                              <hr class="classhr" />
                            </div>

                            <ng-container *ngFor="let campo of grupo.campos">
                              <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'input'"
                                [hidden]="campo.visivel==false">
                                <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label}}</label>
                                <input [type]="campo.tipoComponente" class="form-control" id="{{ campo.nome }}"
                                  [mask]="campo.mascara" name="{{ campo.nome }}" [patterns]="getPattern(campo)"
                                  [formControl]="formGroup.get( getKeyForm(operacao, passo, grupo, campo) )"
                                  placeholder="{{ campo.label }}">

                                <div *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).touched"
                                  class="error">
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['required']">
                                    O campo {{ campo.label }} é obrigatório.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['minlength']">
                                    O {{ campo.label }} deve ter no mínimo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['minlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['maxlength']">
                                    O {{ campo.label }} deve ter no máximo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['maxlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['mask']">
                                    O valor digitado <span class="fst-italic fw-semibold me-1">{{formGroup.get(
                                      getKeyForm(operacao, passo, grupo, campo) ).errors?.['pattern']?.actualValue }}
                                    </span> não corresponde a um {{ campo.label }} válido.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['email']">
                                    O valor digitado <span class="fst-italic fw-semibold me-1">{{formGroup.get(
                                      getKeyForm(operacao, passo, grupo, campo) ).errors?.['pattern']?.actualValue }}
                                    </span> não corresponde a um {{ campo.label }} válido.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['anoInvalido']">
                                    O {{ campo.label }} é inválido.
                                  </div>
                                </div>
                                <!-- debug do campo -->
                                <!--  <pre>{{ campo | json}}</pre> -->
                                <!--  {{ formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors | json }}  -->
                              </div>
                              <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'a_href'"
                                [hidden]="campo.visivel==false">
                                <a href="{{ campo.opcoesCampo[0].valor }}" target="_blank">
                                  <span class="form-label fw-bold">{{ campo.label }}</span>
                                </a>
                                <!-- <pre>{{ campo | json}}</pre> -->
                                <!-- {{ formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors | json }}  -->
                              </div>
                              <div class="col-md-3 p-2" *ngIf="campo.tipoComponente === 'calendar'"
                                [hidden]="campo.visivel==false">
                                <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label }}</label>
                                <div class="row">
                                  <div class="col-md-9">
                                    <div class="input-group">

                                      <input matInput [matDatepicker]="picker1"
                                        [formControl]="formGroup.get(getKeyForm(operacao, passo, grupo, campo))"
                                        id="{{ campo.nome }}" class="form-control" placeholder="DD/MM/YYYY" />
                                      <div class="input-group-append">

                                        <mat-datepicker-toggle matIconSuffix [for]="picker1"
                                          class="mat-datepicker-toggle"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                      </div>
                                    </div>

                                  </div>
                                  <div class="col-md-3">
                                    <!-- Coloque o botão ou outro conteúdo aqui -->
                                  </div>
                                </div>


                                <div *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).touched"
                                  class="error">
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['required']">
                                    O campo {{ campo.label }} é obrigatório.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['minlength']">
                                    O {{ campo.label }} deve ter no mínimo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['minlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['maxlength']">
                                    O {{ campo.label }} deve ter no máximo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['maxlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['mask']">
                                    O valor digitado <span class="fst-italic fw-semibold me-1">{{formGroup.get(
                                      getKeyForm(operacao, passo, grupo, campo) ).errors?.['pattern']?.actualValue }}
                                    </span> não corresponde a um {{ campo.label }} válido.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['email']">
                                    O valor digitado <span class="fst-italic fw-semibold me-1">{{formGroup.get(
                                      getKeyForm(operacao, passo, grupo, campo) ).errors?.['pattern']?.actualValue }}
                                    </span> não corresponde a um {{ campo.label }} válido.
                                  </div>
                                </div>
                                <!-- <pre>{{ campo | json}}</pre> -->
                                <!-- {{ formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors | json }}  -->
                              </div>
                              <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'checkbox'"
                                [hidden]="campo.visivel==false">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" id="{{ campo.nome }}"
                                    name="{{ campo.nome }}"
                                    [checked]="{'true': formGroup.get( getKeyForm(operacao, passo, grupo, campo) ) }"
                                    [formControl]="formGroup.get( getKeyForm(operacao, passo, grupo, campo) )"
                                    (click)="mostrarCamposFilhos(passo, grupo, campo ,$event)">
                                  <label class="form-check-label" for="{{ campo.nome }}">
                                    {{ campo.label }}
                                  </label>
                                </div>
                                <div *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).touched"
                                  class="error">
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['required']">
                                    Campo de ciência obrigatório.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['minlength']">
                                    O {{ campo.label }} deve ter no mínimo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['minlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['maxlength']">
                                    O {{ campo.label }} deve ter no máximo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['maxlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['mask']">
                                    O valor digitado <span class="fst-italic fw-semibold me-1">{{formGroup.get(
                                      getKeyForm(operacao, passo, grupo, campo) ).errors?.['pattern']?.actualValue }}
                                    </span> não corresponde a um {{ campo.label }} válido.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['email']">
                                    O valor digitado <span class="fst-italic fw-semibold me-1">{{formGroup.get(
                                      getKeyForm(operacao, passo, grupo, campo) ).errors?.['pattern']?.actualValue }}
                                    </span> não corresponde a um {{ campo.label }} válido.
                                  </div>
                                </div>
                                <!-- <pre>{{ campo | json}}</pre> -->
                                <!-- {{ formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors | json }}  -->
                              </div>

                              <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'file'"
                                [hidden]="campo.visivel==false">
                                <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label }}</label>

                                <app-files-requerimento [campo]="campo"
                                  [formGroup]="formGroup.get( getKeyForm(operacao, passo, grupo, campo) )"
                                  [filterJsonFormRequerimento]="formGroup" [filesArray]="this.filesToUpload"
                                  [removivel]="false" [conclusao]="false"></app-files-requerimento>

                                <div class="col-md-4" *ngIf="campo.multiColocado">
                                  <div class="mt-4">
                                    <button type="button" class="btn btn-action-requerimentos p-0 no-mobile"
                                      color="primary"
                                      (click)="addFileInput(filterJsonFormRequerimento, operacao, passo, grupo, campo, null)">
                                      <i class="bi bi-file-plus-fill" matTooltip="Adicionar Campos"
                                        style="font-size: 30px;"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'select'"
                                [hidden]="campo.visivel==false">
                                <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label }}</label>
                                <select class="form-select" aria-label="Default select example" id="{{ campo.nome }}"
                                  name="{{ campo.nome }}"
                                  [formControl]="formGroup.get( getKeyForm(operacao, passo, grupo, campo) )">
                                  <option selected value="">Selecione</option>
                                  <option *ngFor="let opcao of campo.opcoesCampo" [ngValue]="opcao.valor">
                                    {{opcao.label}}
                                  </option>
                                </select>

                                <div *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).touched"
                                  class="error">
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['required']">
                                    O campo {{ campo.label }} é obrigatório.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['minlength']">
                                    O {{ campo.label }} deve ter no mínimo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['minlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['maxlength']">
                                    O {{ campo.label }} deve ter no máximo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['maxlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['mask']">
                                    O valor digitado <span class="fst-italic fw-semibold me-1">{{formGroup.get(
                                      getKeyForm(operacao, passo, grupo, campo) ).errors?.['pattern']?.actualValue }}
                                    </span> não corresponde a um {{ campo.label }} válido.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['email']">
                                    O valor digitado <span class="fst-italic fw-semibold me-1">{{formGroup.get(
                                      getKeyForm(operacao, passo, grupo, campo) ).errors?.['pattern']?.actualValue }}
                                    </span> não corresponde a um {{ campo.label }} válido.
                                  </div>
                                </div>
                                <!--  <pre>{{ campo | json}}</pre> -->
                                <!-- {{ formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors | json }}  -->
                              </div>
                              <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'textarea'"
                                [hidden]="campo.visivel==false">
                                <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label }}</label>
                                <textarea [formControl]="formGroup.get( getKeyForm(operacao, passo, grupo, campo) )"
                                  class="form-control" placeholder="Observações sobre o requerimento."
                                  style="height: 100px" id="{{ campo.nome }}" name="{{ campo.nome }}"></textarea>

                                <div *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).touched"
                                  class="error">
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['required']">
                                    O campo {{ campo.label }} é obrigatório.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['minlength']">
                                    O {{ campo.label }} deve ter no mínimo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['minlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['maxlength']">
                                    O {{ campo.label }} deve ter no máximo {{ formGroup.get( getKeyForm(operacao, passo,
                                    grupo, campo) ).errors?.['maxlength']?.requiredLength }} caracteres.
                                  </div>
                                  <div
                                    *ngIf="formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors?.['email']">
                                    O valor digitado <span class="fst-italic fw-semibold me-1">{{formGroup.get(
                                      getKeyForm(operacao, passo, grupo, campo) ).errors?.['pattern']?.actualValue }}
                                    </span> não corresponde a um {{ campo.label }} válido.
                                  </div>
                                </div>
                                <!-- <pre>{{ campo | json}}</pre> -->
                                <!--  {{ formGroup.get( getKeyForm(operacao, passo, grupo, campo) ).errors | json }}  -->
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                        <div class="col-12 mt-5">
                          <button mat-flat-button color="primary" type="button" (click)="selecionarOutroRequerimento()"
                            *ngIf="i==0" style="margin-right: 3px">Selecionar outro requerimento</button>

                          <button mat-flat-button color="primary" type="button" style="margin-right: 3px"
                            (click)="proximoTab(i - 1, operacao, passo, 'backward')" *ngIf="i>0">Voltar</button>

                          <button mat-flat-button color="primary" type="button"
                            (click)="proximoTab(i + 1, operacao, passo, 'forward');">Próximo</button>


                        </div>

                      </div>
                      <div class="tab-pane fade show active--" id="v-pills-requerimento-{{operacaoStep.id}}-conclusao"
                        role="tabpanel" attr.aria-labelledby="v-pills-requerimento-{{operacaoStep.id}}-tab"
                        tabindex="0">
                        <div class="container">
                          <div class="row">
                            <div class="col-12 text-center mb-4">
                              <span class="h3">Confirmação dos dados</span>
                              <!--Conclusão - {{ operacaoStep.nome}} </span>-->
                            </div>

                            <div class="col-md-12">
                              <!--  formulário para visualização -->
                              <form *ngIf="filterJsonFormRequerimento" [formGroup]="filterJsonFormRequerimento"
                                class="filter-form">
                                <!--  chamada recursiva -->
                                <ng-container
                                  *ngTemplateOutlet="readOnlyList; context:{ $implicit: jsonRequerimentoPassos, formGroup:filterJsonFormRequerimento }"></ng-container>
                              </form>
                            </div>


                            <div class="col-12 mt-5">
                              <button mat-flat-button color="primary" type="button" style="margin-right: 3px"
                                (click)="proximoTab(passo.stepNumber - 1, operacao, passo, 'backward')">Voltar
                              </button>

                              <button [disabled]="false" mat-flat-button color="accent" class="float-end" type="button"
                                (click)="salvar()">Confirmar e Abrir o Requerimento
                              </button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!-- SELEÇÃO DE CONCLUSÃO DA ABERTURA -->

                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
      <!--  visualizar os campos preenchidos -->
      <ng-template #readOnlyList let-filterFields let-formGroup="formGroup">
        <ng-container *ngFor="let item of filterFields">
          <ng-container *ngFor="let operacaoStep of item.stepper.operacoes">
            <ng-container *ngIf="operacaoSelecionada !=undefined && operacaoStep.id == operacaoSelecionada">
              <!-- <h1 class="text-center">{{ operacaoStep.nome }}</h1>-->
              <ng-container *ngFor="let operacao of item.stepper.operacoes; let i = index">
                <ng-container *ngIf="operacaoStep.id == operacao.id">
                  <ng-container *ngFor="let passo of operacao.steps; let i = index">
                    <ng-container *ngFor="let grupo of passo.grupos">

                      <div id="grupo_{{ grupo.nome }}_conclusao" [hidden]="!grupo.visivel"
                        [ngClass]="grupo.grupoPai ? 'grupoPai_' + grupo.grupoPai.id : ''">

                        <!--Barra sob o titulo formulário de conclusão-->
                        <div class="divhr" *ngIf="passo.nome != 'documentos'">
                          <p class="fs-4 text-center pt05rem corTituloSobBarra">{{grupo.label}}</p>
                          <hr class="classhr" />
                        </div>

                        <ng-container *ngFor="let campo of grupo.campos">
                          <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'input'">
                            <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label }} :</label>
                            <input [type]="campo.tipoComponente" class="form-control visualizar semBordaFundo"
                              name="{{ campo.nome }}"
                              [formControl]="formGroup.get( getKeyForm(operacao, passo, grupo, campo) )"
                              [value]="getValueVisualizar(operacao, passo, grupo, campo)"
                              placeholder="{{ campo.label }}" style="padding:initial;">
                          </div>
                          <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'a_href'">
                            <a href="{{ campo.opcoesCampo[0].valor }}" target="_blank">
                              <span class="form-label fw-bold">{{ campo.label }}</span>
                            </a>
                          </div>
                          <div class="col-md-3 p-2" *ngIf="campo.tipoComponente === 'calendar'">
                            <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label }}</label>
                            <div class="row">
                              <div class="col-md-9" *ngIf="getValueVisualizar(operacao, passo, grupo, campo)">
                                <p style="font-size: 1rem;">{{moment(getValueVisualizar(operacao, passo, grupo,
                                  campo))?.format('DD/MM/YYYY')}}</p>
                              </div>
                              <div class="col-md-3">
                                <!-- Coloque o botão ou outro conteúdo aqui -->
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12 p-2"
                            *ngIf="campo.tipoComponente === 'checkbox' && getValueVisualizar(operacao, passo, grupo, campo)">
                            <div class="form-check">
                              <input class="form-check-input visualizar" type="checkbox" name="{{ campo.nome }}"
                                [checked]="{'true': getValueVisualizar(operacao, passo, grupo, campo)}"
                                [formControl]="formGroup.get( getKeyForm(operacao, passo, grupo, campo) )">
                              <label class="form-check-label" for="{{ campo.nome }}">
                                {{ campo.label }}
                              </label>
                            </div>
                          </div>
                          <div class="col-md-12 p-2"
                            *ngIf="campo.tipoComponente === 'file' && passo.nome != 'documentos'">
                            <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label }}</label>
                            <input class="form-control visualizar" type="file" name="{{ campo.nome }}"
                              [value]="getValueVisualizar(operacao, passo, grupo, campo)"
                              [formControl]="formGroup.get( getKeyForm(operacao, passo, grupo, campo) )">
                          </div>
                          <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'select'">
                            <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label }}</label>

                            <div class="col-md-9" *ngIf="getValueVisualizar(operacao, passo, grupo, campo)">
                              <p style="font-size: 1rem;">{{getValueVisualizar(operacao, passo, grupo, campo)}}</p>
                            </div>

                          </div>
                          <div class="col-md-12 p-2" *ngIf="campo.tipoComponente === 'textarea'">
                            <label for="{{ campo.nome }}" class="form-label fw-bold">{{ campo.label }}</label>
                            <textarea [formControl]="formGroup.get( getKeyForm(operacao, passo, grupo, campo) )"
                              class="form-control visualizar semBordaFundo" placeholder="{{ campo.nome }}"
                              [value]="getValueVisualizar(operacao, passo, grupo, campo)" style="height: 100px"
                              id="{{ campo.nome }}" name="{{ campo.nome }}"></textarea>
                          </div>


                        </ng-container>

                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container>
                    <p class="fs-4 text-center pt05rem corTituloSobBarra">Documentação Anexada</p>
                    <hr class="classhr" />
                    <div *ngFor="let file of filesToUpload; index as i">
                      <span><b>
                          Arquivo {{i+1}} :
                        </b></span>
                      {{file.filename}}

                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
      <!-- <pre>{{filterJsonFormRequerimento?.value|json}}</pre> -->
      <!-- <pre>{{jsonRequerimentoPassos|json}}</pre> -->
    </div>
    <div class="card-body mt-4" *ngIf="requerimentoaberto">
      <div class="container h-100">
        <div class="row align-items-center h-100">
          <div class="col-md-10 mx-auto text-center">
            <div class="alert alert-success" role="alert">
              <h4 class="alert-heading">Requerimento Aberto com Sucesso!</h4>
              <p>O Nº Protocolo é <strong># {{ requerimento?.numeroProtocolo }} </strong> em breve você receberá um
                e-mail com a confirmação da abertura deste requerimento.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5">
        <button mat-flat-button color="primary" type="button" (click)="selecionarOutroRequerimento()">Selecionar outro
          requerimento</button>
      </div>
    </div>
  </div>
</div>
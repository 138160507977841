import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Requerimento } from '../models/domain/requerimento-class.model';
import { PaginacaoDTO } from '../models/domain/paginacao-dto.model';
import { environment } from 'src/environments/environment';
import { UserResponse } from '../models/authentication/responses/user-response';
import { ServidorResponseDTO } from '../models/domain/ServidorResponseDTO';

@Injectable()
export class ServidorService {
  private servidorUrl: string;

  constructor(private http: HttpClient) {
    this.servidorUrl = environment.apiUrlServidor + '/servidor/';
  }

  public findAllCPF(userResponse: UserResponse) : Observable<ServidorResponseDTO> {
    return this.http.get<ServidorResponseDTO>(this.servidorUrl + 'cpf/' + userResponse.cpf);
  }

  public findByMatricula(matricula: any) : Observable<ServidorResponseDTO> {
    return this.http.get<ServidorResponseDTO>(this.servidorUrl + 'matricula/' + matricula);
  }
}

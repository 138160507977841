<div class="container-fluid bg-white">
  <footer class="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top mb-0">
    <div class="col-md-4 d-flex align-items-center">
      <span class="mb-3 mb-md-0 text-muted"><a href="https://www.ouvidoria.uerj.br/"
          style="color: #000;">Ouvidoria-Geral da UERJ</a> | <a
          href="https://www.ouvidoria.uerj.br/sic-servico-de-informacao-ao-cidadao/" style="color: #000;">Solicitação de
          acesso à informação</a> </span>
    </div>
    <div class="col-md-4 d-flex align-items-center">
      <!--  <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
  <div class="container-fluid bg-white">
    <footer class="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top mb-0">	
	   <div class="col-md-4 d-flex align-items-center">
          <span class="mb-3 mb-md-0 text-muted"><a href="https://www.ouvidoria.uerj.br/" style="color: #000;" target="_blank">Ouvidoria-Geral da UERJ</a> | <a href="https://www.ouvidoria.uerj.br/sic-servico-de-inforamcao-ao-cidadao/" style="color: #000;" target="_blank">Solicitação de acesso à informação</a> </span>
       </div>		
      <div class="col-md-4 d-flex align-items-center">
       <!--  <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          <svg class="bi" width="30" height="24"><use xlink:href="#bootstrap"></use></svg>
        </a> -->
      <a class="navbar-brand d-flex align-items-center me-2" href="https://www.sgp.uerj.br/site/" target="_blank">
        <img class="img-responsive img-thumbnail rounded-5 " width="40" src="./assets/imagens/sgp-logo.png">
        <!-- <span class="p-2 fs-2 fw-bold text-sisre">SISRE</span> -->
      </a>
      <span class="mb-3 mb-md-0 text-muted">© 2023 UERJ, Inc</span>
    </div>

    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
      <!--<li class="ms-3"><a class="text-primary" href="#"><i class="bi bi-" style="font-size: 24px;"></i></a></li>-->
      <li class="ms-3"><a class="text-primary" href="https://api.whatsapp.com/send?phone=552123340416"
          target="_blank"><i class="bi bi-whatsapp" style="font-size: 24px;"></i></a></li>
      <li class="ms-3"><a class="text-primary" href="https://www.instagram.com/sgp.uerj/" target="_blank"><i
            class="bi bi-instagram" style="font-size: 24px;"></i></a></li>
      <li class="ms-3"><a class="text-primary" href="https://www.facebook.com/sgp.uerj" target="_blank"><i
            class="bi bi-facebook" style="font-size: 24px;"></i></a></li>
    </ul>
  </footer>
</div>
import { PapelResponse } from "./papel-response";
import { PerfilResponse } from "./perfil-response";
import { UserResponse } from "./user-response";

export class AuthenticationResponse {

    userResponse: UserResponse;
    accessToken: string;
    message: string;

    constructor(data: any){
        this.userResponse = data.userResponse;
        this.accessToken = data.accessToken;
        this.message = data.message;
    }

}



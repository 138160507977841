<ng-container class="campoWrapper" *ngIf="!campo.valor">

    <div [ngClass]="{'col-sm-6' : campo.multiValorado}">

        <div class="full-width">

            <div [ngClass]="{'row': campo.multiValorado}">

                <div [ngClass]="{'col-10' : campo.multiValorado}">

                    <hr *ngIf="campo.multiValorado" />

                    <!-- Input -->
                    <mat-form-field *ngIf="campo.tipoComponente=='input'" appearance="outline">

                        <mat-label>{{ campo.label}}</mat-label>
                        <input matInput id="{{ campo.id }}" [type]="campo.tipoComponente" [formControl]="campoControl"
                            [mask]="campo.mascara" name="{{ campo.nome }}" />

                        <!-- Errors -->
                        <mat-error *ngIf="campoControl.invalid">{{getErrorMessage()}}</mat-error>

                    </mat-form-field>

                    <!-- TextArea -->
                    <mat-form-field *ngIf="campo.tipoComponente=='textarea'" appearance="outline">

                        <mat-label>{{ campo.label}}</mat-label>
                        <textarea matInput id="{{ campo.id }}" [formControl]="campoControl" name="{{ campo.nome }}"
                            cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="5"></textarea>

                        <!-- Errors -->
                        <mat-error *ngIf="campoControl.invalid">{{getErrorMessage()}}</mat-error>

                    </mat-form-field>

                    <!-- Links -->
                    <ng-container *ngIf="campo.tipoComponente=='a_href'" appearance="outline">
                        <blockquote class="blockquote">
                            <a href="{{ campo.opcoes[0].valor }}" target="_blank">
                                <span class="form-label">{{ campo.label }}</span>
                            </a>
                        </blockquote>

                    </ng-container>

                    <!-- Calendar -->
                    <mat-form-field *ngIf="campo.tipoComponente=='calendar'" appearance="outline">

                        <mat-label>{{ campo.label}}</mat-label>
                        <input matInput id="{{ campo.id }}" [formControl]="campoControl" [matDatepicker]="picker1"
                            name="campo.nome" placeholder="DD/MM/YYYY" />

                        <mat-hint>Formato: dia/mês/ano</mat-hint>

                        <mat-datepicker-toggle matIconSuffix [for]="picker1"
                            class="mat-datepicker-toggle"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>

                        <!-- Errors -->
                        <mat-error *ngIf="campoControl.invalid">{{getErrorMessage()}}</mat-error>

                    </mat-form-field>


                    <!-- Period Calendar -->

                    <ng-container *ngIf="campo.tipoComponente=='period_calendar'" appearance="outline">

                        <app-campo-period-calendar [stepFormGroup]="stepFormGroup" [grupoControl]="grupoControl"
                            [campoControl]="campoControl" [campo]="campo" [grupo]="grupo"></app-campo-period-calendar>

                    </ng-container>

                    <!-- Checkbox -->
                    <mat-checkbox class="mt-2 mb-2" *ngIf="campo.tipoComponente=='checkbox'" id="{{ campo.id }}"
                        [formControl]="campoControl" name="{{ campo.nome }}" [checked]="campo.valor"
                        appearance="outline">

                        <mat-label>
                            <p class="text-justify">{{ campo.label }}</p>
                        </mat-label>

                        <!-- Errors -->
                        <mat-error *ngIf="campoControl.invalid">{{getErrorMessage()}}</mat-error>

                    </mat-checkbox>

                    <!-- File -->
                    <ng-container *ngIf="campo.tipoComponente=='file'" appearance="outline">

                        <app-campo-file [stepFormGroup]="stepFormGroup" [grupoControl]="grupoControl" [campo]="campo"
                            [grupo]="grupo"></app-campo-file>

                    </ng-container>

                    <!-- select -->
                    <mat-form-field *ngIf="campo.tipoComponente=='select'" appearance="outline">
                        <mat-label>{{ campo.label}}</mat-label>
                        <mat-select id="{{ campo.id }}" [formControl]="campoControl" name="{{ campo.nome }}">
                            <mat-option *ngFor="let opcao of campo.opcoes" [value]="opcao">
                                {{opcao?.label}}
                            </mat-option>
                        </mat-select>

                        <!-- Errors -->
                        <mat-error *ngIf="campoControl.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>

                    <!-- Autocomplete Padrão -->
                    <mat-form-field *ngIf="campo.tipoComponente=='autocomplete' && !campo.chamaGrupo"
                        appearance="outline">
                        <mat-label>{{ campo.label }}</mat-label>
                        <input type="text" matInput [formControl]="campoControl" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.label}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <!-- Autocomplete ChamaGrupo -->
                    <ng-container *ngIf="campo.tipoComponente=='autocomplete' && campo.chamaGrupo" appearance="outline">
                        <app-campo-chamargrupo [grupoPai]="grupo" [campo]="campo" [grupoControlPai]="grupoControl"
                            [stepFormGroup]="stepFormGroup"></app-campo-chamargrupo>
                    </ng-container>

                </div>

                <div *ngIf="removivel" class="mt-5" [ngClass]="{'col-2' : campo.multiValorado || campo.chamaGrupo}">
                    <ng-container>
                        <button mat-mini-fab color="primary"
                            (click)="removerCampo()"><mat-icon>delete_forever</mat-icon></button>
                    </ng-container>
                </div>

            </div>

        </div>

    </div>

</ng-container>

<ng-container class="campoWrapper" *ngIf="campo.valor">

    <mat-label style="font-weight: bold;">{{ campo.label}} : </mat-label>
    <mat-label>{{campo.valor}}</mat-label>

</ng-container>
<div class="mt-2 mb-2">
    <div class="card">
        <div class="card-body">

            <div>
                O campo <span class="text-uppercase" style="text-decoration: underline;">{{ campo.label }}</span>
                adiciona novo grupos ao grupo <span class="text-uppercase" style="text-decoration: underline;">{{
                    grupoPai.label }}</span>,
                para adicionar mais grupos selecione dentre as opções:

            </div>

            <div class="row pt-3">

                <mat-form-field class="col-sm-6" appearance="outline">
                    <mat-label>{{ campo.label }}</mat-label>
                    <input type="text" matInput [formControl]="campoControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="adicionarGrupo()">
                        <mat-option *ngFor="let grupo of filteredGrupos | async" [value]="grupo">
                            {{grupo.label}}
                        </mat-option>
                    </mat-autocomplete>

                    <!-- Errors -->
                    <mat-error *ngIf="campoControl.invalid">{{getErrorMessage()}}</mat-error>

                </mat-form-field>

                <!--<ng-template #viewContainerRef></ng-template>-->

            </div>

            <mat-accordion class="pt-1 pb-1">
                <mat-expansion-panel *ngFor="let grupo of gruposCriados;" [expanded]="true" hideToggle="true" #panel>
                    <mat-expansion-panel-header>

                        <mat-panel-title>
                            <mat-icon *ngIf="!panel.expanded">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="panel.expanded">arrow_drop_up</mat-icon>
                            <mat-panel-description>
                                {{grupo.label}}
                            </mat-panel-description>
                        </mat-panel-title>

                        <a class="closePanel" (click)="excluirGrupo(grupo);$event.preventDefault;" mat-button
                            color="primary" matTooltip="Excluir Grupo e Registros"><mat-icon>close_small</mat-icon></a>

                    </mat-expansion-panel-header>
                    <app-grupo *ngIf="!grupo.multiValorado" [grupo]="grupo" [grupoChamador]="grupoPai"
                        [stepFormGroup]="stepFormGroup" [chamado]="true"></app-grupo>
                    <app-grupo-multi-valorado *ngIf="grupo.multiValorado" [grupo]="grupo" [grupoChamador]="grupoPai"
                        [stepFormGroup]="stepFormGroup" [chamado]="true"></app-grupo-multi-valorado>
                </mat-expansion-panel>
            </mat-accordion>

        </div>
    </div>
</div>
<button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="dialogRef.close(false)">
    <mat-icon class="close-icon" color="gray">close</mat-icon>
</button>

<h2 class="text-uppercase" style="width: fit-content;" [ngClass]="{'text-center' : readOnly}" mat-dialog-title> {{
    (titulo) ? titulo :
    'Confirmação
    de
    Ação' }}</h2>
<mat-dialog-content>
    <div class="text-center">
        <mat-icon *ngIf="readOnly" class="material-symbols-outlined mat-icon-cancel">
            cancel
        </mat-icon>

        <p *ngIf="errorMessage">{{errorMessage}}</p>
    </div>
    <p *ngIf="confirmMessage">{{confirmMessage}}</p>
    <p *ngIf="alertMessage" class="text-justify" style="text-align: justify;"><mark>{{alertMessage}}</mark></p>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!readOnly">
    <button mat-button mat-dialog-close cdkFocusInitial (click)="dialogRef.close(false)">Não</button>
    <button mat-button mat-dialog-close (click)="dialogRef.close(true)">Sim</button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="readOnly">
    <div class="text-center w-100">
        <button mat-button mat-dialog-close cdkFocusInitial (click)="dialogRef.close(false)">Ok</button>
    </div>
</mat-dialog-actions>
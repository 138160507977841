// Enum para o tipo de Campo/Componente
export enum CampoEnum {

    a_href = 'a_href',
    autocomplete = 'autocomplete',
    calendar = 'calendar',
    checkbox = 'checkbox',
    file = 'file',
    input = 'input',
    period_calendar = 'period_calendar',
    select = 'select',
    textarea = 'textarea'

}

export enum EnumStatusRequerimento {
    ABERTO = 1,
    VALIDADO = 2,
    PROCESSO_CRIADO = 3,
    FINALIZADO = 4,
    CANCELADO = 5
}

export class getAllStatus  {
    static getStatusRequerimentoArray(): number[] {
        const statusRequerimentoArray: number[] = [];
        for (const value in EnumStatusRequerimento) {
            if (isNaN(Number(value))) {
                statusRequerimentoArray.push(Number(EnumStatusRequerimento[value]));
            }
        }
        return statusRequerimentoArray;
    }
}

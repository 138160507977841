import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationHelper } from 'src/app/core/helpers/authentication.helper';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';

@Component({
  selector: 'app-requerimento-acesso-externo',
  templateUrl: './requerimento-acesso-externo.component.html',
  styleUrls: ['./requerimento-acesso-externo.component.css']
})
export class RequerimentoAcessoExternoComponent {
  numeroProtocolo: string;
  requerimento: any;
  sortedData: any;
  dataSource: any;
  logado: boolean;

  authHelper: AuthenticationHelper;

  constructor(private route: ActivatedRoute, private requerimentoService: RequerimentoService) {

  }


  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.numeroProtocolo = params['numeroProtocolo'];
    });

    this.requerimentoService.findByProtocolo(this.numeroProtocolo).subscribe(data => {
      this.requerimento = data;
      this.requerimento.dataAbertura = this.requerimento.dataAbertura.slice(0, 19);

    });

  }


  ngAfterViewInit(): void {

    this.sortedData = this.requerimento.historico;
    this.dataSource = this.requerimento.historicos;

  }

}

<mat-form-field appearance="outline" class="w-100">
    <mat-label>{{ campo.label }}</mat-label>
    <mat-date-range-input [rangePicker]="rangePicker" (click)="rangePicker.open()">
        <input matStartDate [formControl]="dataInicioControl" placeholder="Data Início" (keydown)="false" readonly />
        <input matEndDate [formControl]="dataFimControl" placeholder="Data Fim" (keydown)="false" readonly />
    </mat-date-range-input>

    <mat-hint>Formato: dia/mês/ano – dia/mês/ano</mat-hint>

    <mat-datepicker-toggle matIconSuffix [for]="rangePicker" class="mat-datepicker-toggle"></mat-datepicker-toggle>

    <mat-date-range-picker #rangePicker>
        <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancelar</button>
            <button mat-raised-button matDateRangePickerApply (click)="validarDatas()">Confirmar</button>
        </mat-date-range-picker-actions>
    </mat-date-range-picker>
</mat-form-field>

<input type="hidden" [formControl]="campoControl" readonly />

<!-- Errors -->
<mat-error *ngIf="campoControl.hasError('required')">O campo período é obrigatório.</mat-error>
<mat-error *ngIf="!dataInicioControl.value && dataFimControl.value && campoControl.hasError('pattern')">Favor
    selecionar a Data de
    Início.</mat-error>
<mat-error *ngIf="dataInicioControl.value && !dataFimControl.value && campoControl.hasError('pattern')">Favor
    selecionar a Data de
    Fim.</mat-error>
<form [formGroup]="stepFormGroup" (ngSubmit)="ultimo ? enviarDadosParaConclusao() : false;" #formStep="ngForm">
  <ng-container *ngFor="let grupo of step.grupos">
    <app-grupo *ngIf="!grupo.multiValorado" [grupo]="grupo" [dadosServidor]="dadosServidor"
      [stepFormGroup]="stepFormGroup"></app-grupo>
    <app-grupo-multi-valorado *ngIf="grupo.multiValorado" [grupo]="grupo"
      [stepFormGroup]="stepFormGroup"></app-grupo-multi-valorado>
    <mat-divider></mat-divider>
  </ng-container>
  <div class="mt-4">
    <button *ngIf="!primeiro" mat-raised-button color="secondary" matStepperPrevious>Voltar</button>
    <button style="margin-left: 5px;" *ngIf="!ultimo" mat-raised-button color="primary" matStepperNext>Próximo</button>
    <button style="margin-left: 5px;" *ngIf="ultimo" mat-raised-button type="button" color="primary"
      (click)="formStep.ngSubmit.emit()" matStepperNext>Próximo</button>
  </div>
</form>
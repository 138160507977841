import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmailMessageDTO } from '../models/email/email-message-dto';
import { EmailResponseDTO } from '../models/email/email-response-dto';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  emailUrl: string;

  constructor(private http: HttpClient) {
    this.emailUrl = environment.apiUrlEmail + '/email/';
  }

  public sendEmail(dto: EmailMessageDTO): Observable<EmailResponseDTO> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<EmailResponseDTO>(this.emailUrl + 'enviar', JSON.stringify(dto), { headers: headers });
  }

}

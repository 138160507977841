<div class="card container px-4 py-5 bg-white shadow">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div class="col-lg-12 text-center">
            <div class="text-center mb-4">
                <img src="./assets/imagens/erro.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes"
                    width="110" loading="lazy">
            </div>
            <span class="display-5 fw-bold lh-1 mb-3">Ocorreu um erro inesperado</span>
            <p class="lead my-3 text-center">Não foi possível prosseguir com a requisição.</p>
            <p class="lead my-3 text-center">Para retornar para a página de Início clique no botão abaixo.</p>
            <button mat-flat-button color="primary" [routerLink]="['/']">Home</button>
        </div>
    </div>
</div>
import { Campo } from "src/app/core/models/domain/Campo";
import { CampoEnum } from "./campo-enum";

/*
    Métodos para verificação do tipo de componente
*/

export function isInput(campo: Campo) {
    return campo.tipoComponente === CampoEnum.input;
}

export function isInputTipo(tipoComponente: string) {
    return tipoComponente === CampoEnum.input;
}

export function isSelect(campo: Campo) {
    return campo.tipoComponente === CampoEnum.select;
}

export function isFile(campo: Campo) {
    return campo.tipoComponente === CampoEnum.file;
}

export function isInputFile(tipoComponente: string) {
    return tipoComponente === CampoEnum.file;
}

export function isAutoComplete(campo: Campo) {
    return campo.tipoComponente === CampoEnum.autocomplete;
}

export function isCalendar(campo: Campo) {
    return campo.tipoComponente === CampoEnum.calendar;
}

export function isLink(campo: Campo) {
    return campo.tipoComponente === CampoEnum.a_href;
}

export function formatCPF(cpf: string): string {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatCNPJ(cpf: string): string {
    return cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function formatTelefone(telefone: string): string {
    return telefone.replace(/(\d{2})(\d{4})(\d{4})/, "($1)$2-$3");
}

export function formatCelular(celular: string): string {
    return celular.replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
}

export function formatCEP(cep: string): string {
    return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
}

export function aplicarMascaraSei(numeroSei: string): string {
    return numeroSei.replace(/^(\d{6})(\d{6})(\d{4})$/, '$1/$2/$3');
}


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AcessoExterno } from '../models/domain/AcessoExterno';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient) { }

  gerarAcessoExterno(request: AcessoExterno): Observable<AcessoExterno> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<AcessoExterno>(environment.apiUrlRequerimento + '/acessoExterno/gerarToken', JSON.stringify(request), { headers: headers });

  };

}

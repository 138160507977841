<div class="card container px-4 py-5 bg-white shadow">
  <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
    <div class="col-lg-12">
      <h2 class="display-5 fw-bold lh-1 mb-3">Tela de Ajuda</h2>
      <div class="accordion" id="helpAccordion">
        <!-- Pergunta 1 -->
        <div class="accordion-item">
          <h3 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Como criar um novo requerimento?
            </button>
          </h3>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#helpAccordion">
            <div class="accordion-body">
              <p>Após a validação do login no SisRe, você deverá acessar o menu principal e clicar no botão
                “Novo Requerimento”.
                
                Selecione o tipo de requerimento dentre as opções disponíveis e realize a conferência e
                preenchimento dos dados complementares.
                
                Caso haja necessidade de anexar documentos, realize no campo “Documentação”.
                
                Por fim, no campo “Conclusão” verifique se todos os dados estão corretos, e em caso positivo,
                clique em “Confirmar e abrir requerimento”</p>
            </div>
          </div>
        </div>
        
        <!-- Pergunta 2 -->
        <div class="accordion-item">
          <h3 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Como acompanhar o status do meu requerimento?
            </button>
          </h3>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#helpAccordion">
            <div class="accordion-body">
              <p>(Não tivemos acesso a função de acompanhamento de requerimentos do usuário no atual
                estado do sistema).</p>
            </div>
          </div>
        </div>
        
        <!-- Pergunta 3 -->
        <div class="accordion-item">
          <h3 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Como alterar minhas informações de contato?
            </button>
          </h3>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#helpAccordion">
            <div class="accordion-body">
              <p>Para usuários que possuem ID único, os dados funcionais são captados dos bancos de dados da
                SGP. Para alterar esses dados é necessário autuar um pedido de Alteração de Cadastro através
                do SISRE.
                
                As informações de Endereço/Contato podem ser alteradas a cada solicitação, antes do seu
                envio.
                
                Após a confirmação do pedido não será mais possível alterar, neste requerimento, os dados
                informados.</p>
            </div>
          </div>
        </div>
        
        <!-- Pergunta 4 -->
        <div class="accordion-item">
          <h3 class="accordion-header" id="headingFour">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              Como cancelar um requerimento em andamento?
            </button>
          </h3>
          <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#helpAccordion">
            <div class="accordion-body">
              <p>(Não tivemos acesso a função de acompanhamento de requerimentos do usuário no atual
                estado do sistema).</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

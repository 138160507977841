import { NgModule } from '@angular/core';

//pages
import { AberturaRequerimentoComponent } from './core/pages/requerimentos/abertura-requerimento/abertura-requerimento.component';
import { IndexComponent } from './core/pages/requerimentos/index/index.component';

import { RouterModule, Routes } from '@angular/router';
import { DataTableComponent } from './core/components/gestao/datatable/datatable.component';
import { FilterComponent } from './core/components/gestao/filter/filter.component';
import { GestaoComponent } from './core/components/gestao/gestao.component';
//import { DashComponent } from './layouts/dash/dash.component';
//import { HomeComponent } from './shared/components/home/home.component';
import { AjudaComponent } from './core/components/ajuda/ajuda.component';
//import { AutheticationComponent } from './core/components/authetication/authetication.component';
import { AcessoNegadoComponent } from './core/components/acesso-negado/acesso-negado.component';
import { ConsultaRequerimentoComponent } from './core/components/consulta-requerimento/consulta-requerimento.component';
import { ErroComponent } from './core/components/erro/erro.component';
import { HomeComponent } from './core/components/home/home.component';
import { InicioExternoComponent } from './core/components/inicio-externo/inicio-externo.component';
import { InicioComponent } from './core/components/inicio/inicio.component';
import { LoginComponent } from './core/components/login/login.component';
import { PerfilComponent } from './core/components/perfil/perfil.component';
//import { AberturaRequerimentoRefComponent } from './core/components/requerimento/abertura/abertura.component';
import { ConclusaoComponent } from './core/components/requerimento/abertura/conclusao/conclusao.component';
import { InicioAberturaRequerimentoComponent } from './core/components/requerimento/abertura/inicio/inicio.component';
import { StepperAberturaRequerimentoComponent } from './core/components/requerimento/abertura/stepper/stepper.component';
import { RequerimentoAcessoExternoComponent } from './core/components/requerimento/acesso-externo/requerimento-acesso-externo.component';
import { RequerimentoComponent } from './core/components/requerimento/requerimento.component';
import { ReenvioTokenComponent } from './core/components/token/reenvio-token/reenvio-token.component';
import { AcessoExternoGuard } from './core/guards/acesso-externo.guard';
import { AuthenticationGuardGuard } from './core/guards/authentication-guard.guard';
import { Role } from './core/models/role';
import { ConsultaExterna } from './core/pages/requerimentos/consulta-requerimento-externa/index/consulta-externa.component';


const routes: Routes = [
  { 
    path: 'filters', 
    component: FilterComponent 
  },
  { 
    path: 'tabela', 
    component: DataTableComponent
  },
  {
    path: 'dashboard',
    component: HomeComponent,
    canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'perfil',
    component: PerfilComponent,
    canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'requerimento',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'abertura'
      },
      { 
        path: 'abertura', 
        component: AberturaRequerimentoComponent
      },
      { 
        path: 'consulta', 
        component: ConsultaRequerimentoComponent 
      },
      { 
        path: 'detalhes/:id', 
        component: RequerimentoComponent // <-- Aqui está o parâmetro "id"
      }, 
      { // Acesso Externo
        path: 'visualizar', 
        component: RequerimentoAcessoExternoComponent,
        canActivate: [AcessoExternoGuard]
      }
    ],
    //TODO: verificar se todas as rotas precisam ser mesmo restritas
    //canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'abertura',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'inicio',
      },
      {
        path: 'inicio',
        component: InicioAberturaRequerimentoComponent, 
      },
      { 
        path: 'step/:data', 
        component: StepperAberturaRequerimentoComponent,  
      },
      { 
        path: 'conclusao/:numeroProtocolo', 
        component: ConclusaoComponent, 
      }
    ]
  },
  {
    path: 'requerimentos',
    component: IndexComponent,
    //canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'consulta-externa',
    component: ConsultaExterna,
    //canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'requerimentos-antigo',
    component: GestaoComponent,
    canActivate: [AuthenticationGuardGuard]
  },

  {
    path: 'ajuda',
    component: AjudaComponent,
  },
  {
    path: 'admin',
    component: GestaoComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'inicio',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'externo'
      },
      {
        path: 'externo',
        component: InicioExternoComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'acessoNegado',
    component: AcessoNegadoComponent,
  },
  {
    path: 'erro',
    component: ErroComponent,
  },
  {
    path: 'reenvio-token',
    component: ReenvioTokenComponent,
    canActivate: [AuthenticationGuardGuard]
  },
  // recomendação da doc do Angular
  // colocar a rota vazia no final
  {
    path: '',
    component: InicioComponent,
    pathMatch: "full"
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="card container px-4 py-5 bg-white shadow">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
      <div class="col-10 col-sm-8 col-lg-6">
        <img src="./assets/imagens/imagem_abertura.jpg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="410" loading="lazy">
      </div>
      <div class="col-lg-6">
        <span class="display-5 fw-bold lh-1 mb-3">Sistema de Requerimentos</span>
        <p class="lead my-3">Através do Sistema de Requerimentos da SGP é possível solicitar de forma intuitiva e simplificada os serviços oferecidos pela Superintendência de Gestão de Pessoas da UERJ.</p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <div class="card" style="width: 18rem;" >
            <div class="card-body">
              <!-- <h5 class="card-title">Special title treatment</h5> -->
              <p class="card-text">Clique abaixo para criar um novo requerimento.</p>
              <!-- <a href="#" class="btn btn-primary">Possui vínculo</a> -->
              <button mat-flat-button color="primary" [routerLink]="'/abertura'">Novo Requerimento</button>
            </div>
          </div>
          <div class="card" style="width: 18rem;">
            <div class="card-body">
             <!--  <h5 class="card-title">Special title treatment</h5> -->
              <p class="card-text">Clique abaixo para consultar um requerimento.</p>
              <!-- <a href="#" class="btn btn-outline-secondary">Não possui vínculo</a> -->
              <button mat-flat-button color="primary" [routerLink]="'/consulta-externa'">Consultar Requerimento</button>
            </div>
          </div>
         <!--  <button type="button" class="btn btn-primary btn-md px-4 me-md-2">Clique aqui caso tenha vínculo com a UERJ</button>
          <button type="button" class="btn btn-outline-secondary btn-md px-4">Clique aqui caso não tenha vínculo com a UERJ</button> -->
        </div>
      </div>
    </div>
  </div>

